<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: false,
                editBtn: false,
                delBtn: true,
                pager: true,
                addUrl: 'league.Feedback.add',
                addPerm: 'league_Feedback_add',
                editUrl: 'league.Feedback.edit',
                editPerm: 'league_Feedback_edit',
                delUrl: 'league.Feedback.delete',
                delPerm: 'league_Feedback_delete',
                listUrl: 'league.Feedback.page',
                formLabelWidth: '100px',
                columns: [
                    {
                        prop: "member_id", label: "反馈人", formatter: row => {
                            return row.member ? row.member.nickname : '匿名'
                        }
                    },
                    {prop: "mobile", label: "联系电话", required: true},
                    {prop: "content", label: "反馈内容", required: true},
                    {
                        prop: "attaches", label: "附件", html: ({row}) => {
                            if (!row.attaches || row.attaches.length <= 0) {
                                return null
                            }
                            const content = row.attaches
                            const result = [];
                            for (const index in content) {
                                result.push(`<a target="_blank" href="${content[index]}"><img class="list-user-avatar" src="${content[index]}" alt="加载失败"/></a>`)
                            }
                            return `<div class="flex-row">${result.toString()}</div>`
                        }
                    }
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
